import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/layout"
import Img from "gatsby-image"

export default function Blog({ data }) {
	return (
		<Layout>
			<article className="blog">
				<header className="entry-header">
					<h1 className="entry-title">Scripting with Windows and Git</h1>
					<div className="entry-meta">
						<span className="sep">Posted on </span>
						<time className="entry-date">July 22, 2017</time>
					</div>
				</header>
				<div className="entry-content">
					<p>Happy Coding!</p>
				</div>
			</article>
		</Layout>
	)
}

